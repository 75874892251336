import {
  Button,
  Divider,
  Grid,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
// import FileUploader from "../../../components/FileUploader/FileUploader";
import SearchableDropdownOB from "../../../components/SearchableDropdownOB/SearchableDropdownOB";
// import FileUpload from "../../../components/FileUpload/FileUpload";
// import CheckboxField from "../../../components/CheckboxField/CheckboxField";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./EmployeePassport.styles";
import { getLookupOptions } from "../../../lookupData.options";
import { Form, FieldArray, Field, Formik, ErrorMessage } from "formik";
import { getEmployeePassportValidation } from "./validation";
import {
  setActiveStep,
  updateFormData,
  submitFormData,
  fetchLookupData,
} from "../../../store/reducers/onboardingReducer";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';  // Optional, if using react-spinners

const EmployeePassport = ({nationality}) => {
  const { i18n, t } = useTranslation();
  const styles = useStyle();

  const { dir } = useSelector((state) => state.generalStates);
  const {
    activeStep,
    employeePassportInfo,
    status,
    lookupData,
    parentId,
    uniqueRecordId,
    // setIsVisaSectionEnabled,
  } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);  // Loader state
  const employeePassportOptions = useMemo(() => getLookupOptions(dir), [dir]);

  const [countryOptions, setCountryOptions] = useState([]);

  const [selectedNationality, setSelectedNationality] = useState('');

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
 
  const initialValues = {
    passportNumber: employeePassportInfo.passportNumber || "",
    issueDate: employeePassportInfo.issueDate || "",
    expiryDate: employeePassportInfo.expiryDate || "",
    issuanceCountry: employeePassportInfo.issuanceCountry || "",
    issuanceEntity: employeePassportInfo.issuanceEntity || "",
    // unifiedNumber: employeePassportInfo.unifiedNumber || "",
    uniqueRecordId: employeePassportInfo.uniqueRecordId,
  };

  const formatDate = (date) => {
    try {
      const formattedDate = date.toISOString();
      return formattedDate;
    } catch (_) {
      return null;
    }
  };


  // Fetch lookup data on component mount
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLookupData(["Country"]));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "succeeded") {
      // Update country options
      if (lookupData.Country) {
        setCountryOptions(
          lookupData.Country.map((item) => ({
            labelEN: item.Value, // English label
            labelAR: item.ValueAR, // Arabic label
            value: item.Key,
          }))
        );
      }
    }
  }, [lookupData, status]);
  

  const onClickSubmit = async (values) => {
    setIsLoading(true);
    // console.log("Form Values on Submit:", values);
    // Combine fields into a single object for submission
    const employeePassportInfo = { ...values };
    // console.log("Form Values on Submit:", employeePassportInfo);
    // Dispatch the current form data to Redux, including the step number
    dispatch(updateFormData({ step: activeStep, data: employeePassportInfo }));
    try {
      const result = await dispatch(
        submitFormData({ employeePassportInfo, activeStep, parentId })
      );
      if (result.meta.requestStatus === "fulfilled") {

        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        if(nationality === t("United Arab Emirates"))
        {
          const newActiveStep =  activeStep + 2;
            dispatch(setActiveStep(newActiveStep ));
        }
        else
        {
          dispatch(setActiveStep(activeStep + 1));
        }
        // console.log("Form submitted successfully");
        setIsLoading(false);
      } else {
       throw new Error(
          result.payload || t("Something went wrong. We are having trouble completing your request, please try again")
        );
      }
    } catch (error) {
      // console.error("Submission error:", error);
      // setIsLoading(false);
      setSnackBarMessage(
        t(
          "Something went wrong. We are having trouble completing your request, please try again"
        )
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state in both success and error cases
      setSnackBarOpen(true); // Open Snackbar with the message
    }
  };

  const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',  // Centers horizontally
    alignItems: 'center',      // Centers vertically
    //height: '100vh'            // Full viewport height
  };

  const loaderStyle = {
    border: '8px solid #f3f3f3',  // Light grey
    borderTop: '8px solid #3498db', // Blue
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 2s linear infinite'
  };

  const spinKeyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  if (isLoading) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinKeyframes}</style> {/* Add the spin animation */}
        <div style={loaderStyle}></div>  {/* Custom loader */}
      </div>
    );
  }

  const isArabic = i18n.language === "ar";
  return (
    <>
     <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: isArabic ? "left" : "right",
        }} // Positioning
        style={{ top: "100px" }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    <Formik
      initialValues={{
        employeePassportInfo: initialValues,
      }}
      validationSchema={getEmployeePassportValidation(t)}
      onSubmit={(values) => onClickSubmit(values)}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        setFieldTouched,
      }) => (
        <>
          {/* <input id="parentid" style={{ display: "none" }} value={parentId} />
        <input id="qIuniqueId" style={{ height: "1px", display: "none"}} value={uniqueRecordId} /> */}
          <Form>
            <Grid
              className={styles.container}
              container
              direction="row"
              justifyContent="flex-start"
            >
              <InputField
                containerClass={`${styles.inputField}`}
                name="employeePassportInfo.passportNumber"
                placeholder="Passport Number"
                helperText={
                  touched?.employeePassportInfo?.passportNumber &&
                  errors?.employeePassportInfo?.passportNumber
                }
                className={classNames({
                  [styles.error]:
                    touched?.employeePassportInfo?.passportNumber &&
                    Boolean(errors?.employeePassportInfo?.passportNumber),
                })}
                value={values.employeePassportInfo.passportNumber} // Correctly bind the value
                onChange={(e) => {
                  setFieldValue(
                    "employeePassportInfo.passportNumber",
                    e.target.value
                  );
                  // setFieldTouched("employeePassportInfo.passportNumber", true); // Mark as touched on change
                }}
                onBlur={() => {
                  setFieldTouched("employeePassportInfo.passportNumber", true); // Trigger validation on blur
                }}
                onFocus={() => {
                  setFieldTouched("employeePassportInfo.passportNumber", true); // Mark field as touched on focus
                }}
              />

              <CustomDatePicker
                name="employeePassportInfo.issueDate"
                placeholder="Issue Date"
                helperText={
                  touched.employeePassportInfo?.issueDate &&
                  errors.employeePassportInfo?.issueDate
                }
                className={classNames(styles.inputField, {
                  [styles.error]:
                    touched.employeePassportInfo?.issueDate &&
                    Boolean(errors.employeePassportInfo?.issueDate),
                })}
                onChange={(date) => {
                  // Convert date to ISO string or set to empty string if null
                  const isoDate = date instanceof Date && !isNaN(date) ? date.toISOString() : "";
                  setFieldValue("employeePassportInfo.issueDate", isoDate);
                }}
                value={values.employeePassportInfo.issueDate || ""}
                onBlur={() => {
                  setFieldTouched("employeePassportInfo.issueDate", true); // Trigger validation on blur
                }}
                onFocus={() => {
                  setFieldTouched("employeePassportInfo.issueDate", true); // Mark field as touched on focus
                }}
              />

              <CustomDatePicker
                name="employeePassportInfo.expiryDate"
                placeholder="Expiry Date"
                helperText={
                  touched.employeePassportInfo?.expiryDate &&
                  errors.employeePassportInfo?.expiryDate
                }
                className={classNames(styles.inputField, {
                  [styles.error]:
                    touched.employeePassportInfo?.expiryDate &&
                    Boolean(errors.employeePassportInfo?.expiryDate),
                })}
                onChange={(date) => {
                  // Convert date to ISO string or set to empty string if null
                  const isoDate = date instanceof Date && !isNaN(date) ? date.toISOString() : "";
                  setFieldValue("employeePassportInfo.expiryDate", isoDate);
                }}
                value={values.employeePassportInfo.expiryDate || ""}
                onBlur={() => {
                  setFieldTouched("employeePassportInfo.expiryDate", true); // Trigger validation on blur
                }}
                onFocus={() => {
                  setFieldTouched("employeePassportInfo.expiryDate", true); // Mark field as touched on focus
                }}
              />

<SearchableDropdownOB
                name="employeePassportInfo.issuanceCountry"
                containerClass={`${styles.inputField}`}
                // Render options with dynamic labels based on the language direction (RTL or LTR)
                options={countryOptions.map((option) => ({
                  label: dir === "rtl" ? option.labelAR : option.labelEN, // Use labelAR for Arabic and labelEN for English
                  value: option.value,
                }))}
                placeholder="Issuance Country"
                onChange={(_, value) => {
                  const selectedKey = value?.value;
                  setFieldValue(
                    "employeePassportInfo.issuanceCountry",
                    selectedKey
                  );
                }}
                value={
                  countryOptions.find(
                    (option) =>
                      option.value ===
                      values.employeePassportInfo?.issuanceCountry
                  )
                    ? {
                        label:
                          dir === "rtl"
                            ? countryOptions.find(
                                (option) =>
                                  option.value ===
                                  values.employeePassportInfo?.issuanceCountry
                              ).labelAR
                            : countryOptions.find(
                                (option) =>
                                  option.value ===
                                  values.employeePassportInfo?.issuanceCountry
                              ).labelEN,
                        value: values.employeePassportInfo?.issuanceCountry,
                      }
                    : "" // Ensure value is empty if not found
                }
                helperText={
                  touched.employeePassportInfo?.issuanceCountry &&
                  errors.employeePassportInfo?.issuanceCountry
                }
                className={classNames({
                  [styles.error]:
                    touched.employeePassportInfo?.issuanceCountry &&
                    Boolean(errors.employeePassportInfo?.issuanceCountry),
                })}
                onBlur={() => {
                  setFieldTouched("employeePassportInfo.issuanceCountry", true); // Trigger validation on blur
                }}
                onFocus={() => {
                  setFieldTouched("employeePassportInfo.issuanceCountry", true); // Mark field as touched on focus
                }}
              />

              <InputField
                containerClass={`${styles.inputField}`}
                name="employeePassportInfo.issuanceEntity"
                placeholder="Issuance Entity"
                value={values.employeePassportInfo.issuanceEntity}
                onChange={(e) =>
                  setFieldValue(
                    "employeePassportInfo.issuanceEntity",
                    e.target.value
                  )
                }
                helperText={
                  touched.employeePassportInfo?.issuanceEntity &&
                  errors.employeePassportInfo?.issuanceEntity
                }
                className={classNames({
                  [styles.error]:
                    touched.employeePassportInfo?.issuanceEntity &&
                    Boolean(errors.employeePassportInfo?.issuanceEntity),
                })}
                onBlur={() => {
                  setFieldTouched("employeePassportInfo.issuanceEntity", true); // Trigger validation on blur
                }}
                onFocus={() => {
                  setFieldTouched("employeePassportInfo.issuanceEntity", true); // Mark field as touched on focus
                }}
              />

              {/* <InputField
                containerClass={`${styles.inputField}`}
                name="employeePassportInfo.unifiedNumber"
                placeholder="Unified Number"
                value={values.employeePassportInfo.unifiedNumber}
                onChange={(e) =>
                  setFieldValue(
                    "employeePassportInfo.unifiedNumber",
                    e.target.value
                  )
                }
                helperText={
                  touched.employeePassportInfo?.unifiedNumber &&
                  errors.employeePassportInfo?.unifiedNumber
                }
                className={classNames({
                  [styles.error]:
                    touched.employeePassportInfo?.unifiedNumber &&
                    Boolean(errors.employeePassportInfo?.unifiedNumber),
                })}
              /> */}
            </Grid>

            <Divider style={{ margin: "20px 0" }} />
            <ActionButtons nationality={selectedNationality} />
          </Form>
        </>
      )}
    </Formik>
    </>
  );
};

export default EmployeePassport;
