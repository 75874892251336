import * as Yup from "yup";

// const apiKeyUAE = process.env.REACT_APP_UAE;

// const apiKeyABUDHABI = process.env.REACT_APP_ABUDHABI;


export const getEmployeeDependentDetailsValidation = (t) => {
  return Yup.object().shape({
    employeeDependentDetails: Yup.array().of(
      Yup.object().shape({
        Relationship: Yup.string().required(t('Spouse is required')),
        // name: Yup.string().required(t("Dependent's Name is required")),
        name: Yup
          .string()
          .required(t("Dependent's Name is required"))
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(50, t("Character count exceeded")),
        DateOfBirth: Yup.date()
          .typeError(t("Date of Birth is required"))
          .required(t('Date of Birth is required'))
          .max(new Date(), t("Date of birth cannot be later than today.")),
        gender: Yup.string().required(t('Gender is Required')),
        Country: Yup.mixed().required(t("Please select an item in the list")),
        // City: Yup.string().required(t('City is required')),
        City: Yup
          .string()
          .required(t("City is required"))
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(50, t("Character count exceeded")),
        Emirate: Yup.string().when("Country", {
          is: (value) => {
            return value === "d6a2c1a9-c393-ef11-b850-945680105b98";      // UAE ID on production: d6a2c1a9-c393-ef11-b850-945680105b98
            // return value === "3be64a90-a285-ef11-b843-005056b3b9cd";   // UAE ID on staging: 3be64a90-a285-ef11-b843-005056b3b9cd
          },
          // Assuming you only want this field to be required if it's NOT 'UAE'
          then: Yup.string().required(t("Please select an item in the list")),
          otherwise: Yup.string().notRequired(), // If the selected country is 'UAE', it's not required
        }),

        Region: Yup.string().when("Emirate", {
          is: (value) => value === "27f1f150-c493-ef11-b850-945680105b98",      // Abu Dhabi emirates id on production: 27f1f150-c493-ef11-b850-945680105b98
          // is: (value) => value === "12028452-5086-ef11-b843-005056b3b9cd",    // Abu Dhabi emirates id on staging: 12028452-5086-ef11-b843-005056b3b9cd
          then: Yup.string().required(t("Please select an item in the list")),
          otherwise: Yup.string().notRequired(),
        }),

      })
    )
  });
};