// // new design for employee Dependent Details,
import { Button, Divider, Grid, Snackbar } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import { getLookupOptions } from "../../../lookupData.options";
import ActionButtons from "../ActionButtons/ActionButtons";
import { Form, FieldArray, Field, Formik } from "formik";
import { getEmployeeDependentDetailsValidation } from "./validation";
import {
  setActiveStep,
  updateFormData,
  submitFormData,
  fetchLookupData,
} from "../../../store/reducers/onboardingReducer";
import classNames from "classnames";
import useStyle from "./EmployeeDependentDetails.styles"; // Import your styles
import SearchableDropdownOB from "../../../components/SearchableDropdownOB/SearchableDropdownOB";
import ClipLoader from "react-spinners/ClipLoader"; // Optional, if using react-spinners

const EmployeeDependentDetails = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const {
    activeStep,
    status,
    lookupData,
    selectedFile,
    parentId,
    uniqueRecordId,
    employeeDependentDetails,
  } = useSelector((state) => state.onboarding);
  const { dir } = useSelector((state) => state.generalStates);
  const styles = useStyle(); // Get styles
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const dependentOptions = useMemo(() => getLookupOptions(dir), [dir]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [countryLookup, setCountryLookup] = useState({});

  const [emiratesOptions, setEmiratesOptions] = useState([]);
  const [emiratesLookup, setEmiratesLookup] = useState({});

  const [regionOptions, setRegionOptions] = useState([]);
  const [regionLookup, setRegionLookup] = useState({});

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  // Initialize dependents data
  const initialDependentData =
    Array.isArray(employeeDependentDetails) &&
    employeeDependentDetails.length > 0
      ? employeeDependentDetails.map((detail) => ({
          Relationship: detail.Relationship || "", // Relationship Textbox
          name: detail.name || "", // Name Textbox
          DateOfBirth: detail.DateOfBirth || "", // Date of Birth Date Picker
          gender: detail.gender || "", // Gender Textbox
          Country: detail.Country || "", // Country Textbox
          Emirate: detail.Emirate || "", // Emirate Textbox
          City: detail.City || "", // City Textbox
          Region: detail.Region || "", // Region Textbox
          uniqueRecordId: detail.uniqueRecordId || "", // Region Textbox
        }))
      : [];

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLookupData(["Country", "Emirates", "Region"]));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "succeeded") {
      // Update country options
      // Check for `Country` data
      if (lookupData?.Country?.length) {
        const countryMap = {};
        const mappedOptions = lookupData.Country.map((item) => {
          countryMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setCountryOptions(mappedOptions);
        setCountryLookup(countryMap); // Store the lookup map
      }
      // Check for `Emirates` data
      if (lookupData?.Emirates?.length) {
        const EmiratesMap = {};
        const mappedOptions = lookupData.Emirates.map((item) => {
          EmiratesMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            // regionID: item.RegionId, // Assuming this exists in the district data
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setEmiratesOptions(mappedOptions);
        setEmiratesLookup(EmiratesMap); // Store the lookup map
      }
      if (lookupData?.Region?.length) {
        const regionMap = {};
        const mappedOptions = lookupData.Region.map((item) => {
          regionMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            dmtHubId: item.DmtHubId, // Store the DmtHubId for filtering districts
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setRegionOptions(mappedOptions);
        setRegionLookup(regionMap); // Store the lookup map
      }
    }
  }, [lookupData, status]);

  const initialValues = {
    employeeDependentDetails: initialDependentData,
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLookupData(["Country"]));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "succeeded") {
      // Check for `Country` data
      if (lookupData?.Country?.length) {
        const countryMap = {};
        const mappedOptions = lookupData.Country.map((item) => {
          countryMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setCountryOptions(mappedOptions);
        setCountryLookup(countryMap); // Store the lookup map
      }
      // console.log("Lookup Data:", lookupData);
    }
  }, [lookupData, status]);

  const onClickSubmit = async (values) => {
    setIsLoading(true);
    // const employeeDependentDetailsData = values.employeeDependentDetails.filter(
    //   (dep) => dep.name || dep.DateOfBirth
    // );
    let employeeDependentDetailsData = [...values.employeeDependentDetails];
    dispatch(
      updateFormData({ step: activeStep, data: employeeDependentDetailsData })
    );

    try {
      const result = await dispatch(
        submitFormData({
          employeeDependentDetails: employeeDependentDetailsData,
          activeStep,
        })
      );
      if (result.meta.requestStatus === "fulfilled") {
        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        dispatch(setActiveStep(activeStep + 1));
        // setIsLoading(false);
      } else {
        throw new Error(
          result.payload ||t("Something went wrong. We are having trouble completing your request, please try again")
        );
      }
    } catch (error) {
      // Show error message using Snackbar
      setSnackBarMessage(
        t(
          "Something went wrong. We are having trouble completing your request, please try again"
        )
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state in both success and error cases
      setSnackBarOpen(true); // Open Snackbar with the message
    }
  };
  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    //height: '100vh'            // Full viewport height
  };

  const loaderStyle = {
    border: "8px solid #f3f3f3", // Light grey
    borderTop: "8px solid #3498db", // Blue
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  };

  const spinKeyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  if (isLoading) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinKeyframes}</style> {/* Add the spin animation */}
        <div style={loaderStyle}></div> {/* Custom loader */}
      </div>
    );
  }
  const isArabic = i18n.language === "ar";
  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: isArabic ? "left" : "right",
        }} // Positioning
        style={{ top: "100px" }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={initialValues}
        validationSchema={getEmployeeDependentDetailsValidation(t)}
        onSubmit={onClickSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          setFieldTouched,
        }) => (
          <Form>
            <FieldArray name="employeeDependentDetails">
              {({ push, remove }) => (
                <>
                  {values.employeeDependentDetails.map((_, index) => {
                    // Find selected country option
                    const selectedCountryOption = countryOptions.find(
                      (option) =>
                        option.value ===
                        values.employeeDependentDetails[index]?.Country
                    );

                    const dropdownValueCountry = selectedCountryOption
                      ? {
                          label:
                            dir === "rtl"
                              ? selectedCountryOption.labelAR
                              : selectedCountryOption.labelEN,
                          value:
                            values.employeeDependentDetails[index]?.Country,
                        }
                      : { label: "", value: "" }; // Default

                    const countryLabel = selectedCountryOption
                      ? dir === "rtl"
                        ? selectedCountryOption.labelAR
                        : selectedCountryOption.labelEN
                      : "";

                    const isUAE =
                      countryLabel === "United Arab Emirates" ||
                      countryLabel === "الإمارات العربية المتحدة";

                    // Find selected emirate option
                    const selectedEmirateOption = emiratesOptions.find(
                      (option) =>
                        option.value ===
                        values.employeeDependentDetails[index]?.Emirate
                    );

                    const dropdownValueEmirate = selectedEmirateOption
                      ? {
                          label:
                            dir === "rtl"
                              ? selectedEmirateOption.labelAR
                              : selectedEmirateOption.labelEN,
                          value:
                            values.employeeDependentDetails[index]?.Emirate,
                        }
                      : { label: "", value: "" }; // Default

                    const emirateLabel = selectedEmirateOption
                      ? dir === "rtl"
                        ? selectedEmirateOption.labelAR
                        : selectedEmirateOption.labelEN
                      : "";

                    const isAbuDhabi =
                      emirateLabel === "Abu Dhabi" ||
                      emirateLabel === "أبو ظبي"; // Check if selected emirate is Abu Dhabi

                    return (
                      <React.Fragment key={index}>
                        {values.employeeDependentDetails.length > 0 && (
                          <Button
                            className={styles.textButtonRemove}
                            variant="text"
                            color="secondary"
                            startIcon={<Remove fontSize="small" />}
                            onClick={() => remove(index)}
                          >
                            {t("Remove Employee Dependent")}
                          </Button>
                        )}
                        <Grid
                          className={styles.container}
                          container
                          direction="row"
                          justifyContent="flex-start"
                        >
                          <Field
                            name={`employeeDependentDetails[${index}].Relationship`}
                            component={Dropdown}
                            options={dependentOptions.RelationshipOptions}
                            label="Relationship"
                            containerClassName={`${styles.dropdown}`}
                            helperText={
                              touched.employeeDependentDetails?.[index]
                                ?.Relationship &&
                              errors.employeeDependentDetails?.[index]
                                ?.Relationship
                            }
                            className={classNames(styles.dropdownInput, {
                              [styles.error]:
                                touched.employeeDependentDetails?.[index]
                                  ?.Relationship &&
                                Boolean(
                                  errors.employeeDependentDetails?.[index]
                                    ?.Relationship
                                ),
                            })}
                            value={
                              values.employeeDependentDetails[index]
                                .Relationship
                            }
                            onChange={(event) => {
                              const value = event.target.value; // Get the value from the event
                              setFieldValue(
                                `employeeDependentDetails[${index}].Relationship`,
                                value
                              );
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `employeeDependentDetails[${index}].Relationship`,
                                true
                              )
                            } // Trigger validation on blur
                            onFocus={() => {
                              setFieldTouched(
                                `employeeDependentDetails[${index}].Relationship`,
                                true
                              ); // Mark field as touched on focus
                            }}
                          />

                          <Field
                            name={`employeeDependentDetails[${index}].name`}
                            component={InputField}
                            placeholder="Dependent's Name"
                            containerClass={`${styles.inputField}`}
                            helperText={
                              touched.employeeDependentDetails?.[index]?.name &&
                              errors.employeeDependentDetails?.[index]?.name
                            }
                            className={classNames(styles.inputField, {
                              [styles.error]:
                                touched.employeeDependentDetails?.[index]
                                  ?.name &&
                                Boolean(
                                  errors.employeeDependentDetails?.[index]?.name
                                ),
                            })}
                            value={values.employeeDependentDetails[index].name}
                            style={{ width: "100%" }}
                            onBlur={() =>
                              setFieldTouched(
                                `employeeDependentDetails[${index}].name`,
                                true
                              )
                            } // Trigger validation on blur
                            onFocus={() => {
                              setFieldTouched(
                                `employeeDependentDetails[${index}].name`,
                                true
                              ); // Mark field as touched on focus
                            }}
                          />
                          <Field
                            name={`employeeDependentDetails[${index}].DateOfBirth`}
                            placeholder="Date of Birth" // Add the placeholder here
                            component={CustomDatePicker}
                            helperText={
                              Object.keys(touched)?.length > 0 &&
                              Object.keys(errors)?.length > 0 &&
                              touched.employeeDependentDetails?.[index]
                                ?.DateOfBirth &&
                              errors.employeeDependentDetails?.[index]
                                ?.DateOfBirth
                            }
                            className={classNames(styles.inputField, {
                              [styles.error]:
                                Object.keys(touched).length > 0 &&
                                Object.keys(errors).length > 0 &&
                                touched?.employeeDependentDetails?.[index]
                                  ?.DateOfBirth &&
                                Boolean(
                                  errors?.employeeDependentDetails?.[index]
                                    ?.DateOfBirth
                                ),
                            })}
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: `employeeDependentDetails.${index}.DateOfBirth`, // Updated field name
                                  value: date,
                                },
                              })
                            }
                            value={
                              values.employeeDependentDetails[index]
                                .DateOfBirth || ""
                            }
                            onBlur={() =>
                              setFieldTouched(
                                `employeeDependentDetails[${index}].DateOfBirth`,
                                true
                              )
                            } // Trigger validation on blur
                            onFocus={() => {
                              setFieldTouched(
                                `employeeDependentDetails[${index}].DateOfBirth`,
                                true
                              ); // Mark field as touched on focus
                            }}
                          />

                          <Field
                            name={`employeeDependentDetails[${index}].gender`}
                            component={Dropdown}
                            options={dependentOptions.genderOptions}
                            label="Gender"
                            containerClassName={`${styles.dropdown}`}
                            helperText={
                              touched.employeeDependentDetails?.[index]
                                ?.gender &&
                              errors.employeeDependentDetails?.[index]?.gender
                            }
                            className={classNames(styles.dropdownInput, {
                              [styles.error]:
                                touched.employeeDependentDetails?.[index]
                                  ?.gender &&
                                Boolean(
                                  errors.employeeDependentDetails?.[index]
                                    ?.gender
                                ),
                            })}
                            value={
                              values.employeeDependentDetails[index].gender
                            }
                            onChange={(event) => {
                              const value = event.target.value; // Get the value from the event
                              setFieldValue(
                                `employeeDependentDetails[${index}].gender`,
                                value
                              );
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `employeeDependentDetails[${index}].gender`,
                                true
                              )
                            } // Trigger validation on blur
                            onFocus={() => {
                              setFieldTouched(
                                `employeeDependentDetails[${index}].gender`,
                                true
                              ); // Mark field as touched on focus
                            }}
                          />
                          <SearchableDropdownOB
                            containerClass={styles.inputField}
                            className={classNames({
                              [styles.error]:
                                touched?.employeeDependentDetails?.[index]
                                  ?.Country &&
                                Boolean(
                                  errors?.employeeDependentDetails?.[index]
                                    ?.Country
                                ),
                            })}
                            placeholder={t("Country")}
                            name={`employeeDependentDetails[${index}].Country`}
                            onChange={(_, value) => {
                              const selectedKey = value?.value;
                              // console.log("Dropdown value changed:", value);
                              setFieldValue(
                                `employeeDependentDetails[${index}].Country`,
                                selectedKey
                              );
                              if (value) {
                                const label =
                                  dir === "rtl"
                                    ? countryLookup[selectedKey]?.labelAR
                                    : countryLookup[selectedKey]?.labelEN;

                                setFieldValue(
                                  `employeeDependentDetails[${index}].CountryLabel`,
                                  label || ""
                                );
                              }
                              if(countryLabel !== t("United Arab Emirates")){
                                setFieldValue(`employeeDependentDetails[${index}].Emirate`, "");

                              }
                            }}
                            options={countryOptions.map((option) => ({
                              label:
                                dir === "rtl" ? option.labelAR : option.labelEN,
                              value: option.value,
                            }))}
                            value={dropdownValueCountry}
                            helperText={
                              touched?.employeeDependentDetails?.[index]
                                ?.Country &&
                              errors?.employeeDependentDetails?.[index]?.Country
                            }
                            onBlur={() =>
                              setFieldTouched(
                                `employeeDependentDetails[${index}].Country`,
                                true
                              )
                            } // Trigger validation on blur
                            onFocus={() => {
                              setFieldTouched(
                                `employeeDependentDetails[${index}].Country`,
                                true
                              ); // Mark field as touched on focus
                            }}
                          />
                          {isUAE && (
                            <SearchableDropdownOB
                              containerClass={styles.inputField}
                              className={classNames({
                                [styles.error]:
                                  touched?.employeeDependentDetails?.[index]
                                    ?.Emirate &&
                                  Boolean(
                                    errors?.employeeDependentDetails?.[index]
                                      ?.Emirate
                                  ),
                              })}
                              placeholder={t("Emirate")}
                              name={`employeeDependentDetails[${index}].Emirate`}
                              onChange={(_, value) => {
                                const selectedKey = value?.value;
                                // console.log(
                                //   "Emirate dropdown value changed:",
                                //   value
                                // );

                                setFieldValue(
                                  `employeeDependentDetails[${index}].Emirate`,
                                  selectedKey
                                );

                                // Set the EmirateLabel based on the selected value
                                if (value) {
                                  const label =
                                    dir === "rtl"
                                      ? emiratesLookup[selectedKey]?.labelAR
                                      : emiratesLookup[selectedKey]?.labelEN;

                                  setFieldValue(
                                    `employeeDependentDetails[${index}].EmirateLabel`,
                                    label || ""
                                  );
                                }
                                if(emirateLabel !== t("Abu Dhabi")){
                                  setFieldValue(`employeeDependentDetails[${index}].Region`, "");
                                }
                              }}
                              options={emiratesOptions.map((option) => ({
                                label:
                                  dir === "rtl"
                                    ? option.labelAR
                                    : option.labelEN,
                                value: option.value,
                              }))}
                              value={dropdownValueEmirate}
                              // value={
                              //   emiratesLookup[
                              //     values.employeeDependentDetails[index]?.Emirate
                              //   ]
                              //     ? {
                              //         label:
                              //           dir === "rtl"
                              //             ? emiratesLookup[
                              //                 values.employeeDependentDetails[
                              //                   index
                              //                 ]?.Emirate
                              //               ]?.labelAR
                              //             : emiratesLookup[
                              //                 values.employeeDependentDetails[
                              //                   index
                              //                 ]?.Emirate
                              //               ]?.labelEN,
                              //         value:
                              //           values.employeeDependentDetails[index]
                              //             ?.Emirate,
                              //       }
                              //     : { label: "", value: "" }
                              // }
                              helperText={
                                touched?.employeeDependentDetails?.[index]
                                  ?.Emirate &&
                                errors?.employeeDependentDetails?.[index]
                                  ?.Emirate
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `employeeDependentDetails[${index}].Emirate`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `employeeDependentDetails[${index}].Emirate`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                          )}
                          {isAbuDhabi && (
                            <Field
                              name={`employeeDependentDetails[${index}].Region`}
                              placeholder={t("Region")}
                              containerClass={`${styles.inputField}`}
                              options={regionOptions.map((option) => ({
                                label:
                                  dir === "rtl"
                                    ? option.labelAR
                                    : option.labelEN,
                                value: option.value,
                              }))}
                              component={SearchableDropdownOB}
                              className={classNames({
                                [styles.error]:
                                  touched?.employeeDependentDetails?.[index]
                                    ?.Region &&
                                  Boolean(
                                    errors?.employeeDependentDetails?.[index]
                                      ?.Region
                                  ),
                              })}
                              helperText={
                                touched?.employeeDependentDetails?.[index]
                                  ?.Region &&
                                errors?.employeeDependentDetails?.[index]
                                  ?.Region
                              }
                              onChange={(_, value) => {
                                const selectedKey = value?.value;
                                setFieldValue(
                                  `employeeDependentDetails[${index}].Region`,
                                  selectedKey
                                );
                              }}
                              value={
                                regionLookup[
                                  values.employeeDependentDetails[index]?.Region
                                ]
                                  ? {
                                      label:
                                        dir === "rtl"
                                          ? regionLookup[
                                              values.employeeDependentDetails[
                                                index
                                              ]?.Region
                                            ].labelAR
                                          : regionLookup[
                                              values.employeeDependentDetails[
                                                index
                                              ]?.Region
                                            ].labelEN,
                                      value:
                                        values.employeeDependentDetails[index]
                                          ?.Region,
                                    }
                                  : { label: "", value: "" }
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `employeeDependentDetails[${index}].Region`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `employeeDependentDetails[${index}].Region`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                          )}
                          <Field
                            name={`employeeDependentDetails[${index}].City`}
                            component={InputField}
                            placeholder="City"
                            containerClass={`${styles.inputField}`}
                            helperText={
                              touched.employeeDependentDetails?.[index]?.City &&
                              errors.employeeDependentDetails?.[index]?.City
                            }
                            className={classNames(styles.inputField, {
                              [styles.error]:
                                touched.employeeDependentDetails?.[index]
                                  ?.City &&
                                Boolean(
                                  errors.employeeDependentDetails?.[index]?.City
                                ),
                            })}
                            value={values.employeeDependentDetails[index].City}
                            style={{ width: "100%" }}
                            onBlur={() =>
                              setFieldTouched(
                                `employeeDependentDetails[${index}].City`,
                                true
                              )
                            } // Trigger validation on blur
                            onFocus={() => {
                              setFieldTouched(
                                `employeeDependentDetails[${index}].City`,
                                true
                              ); // Mark field as touched on focus
                            }}
                          />
                        </Grid>
                        {index < values.employeeDependentDetails.length - 1 && (
                          <Divider />
                        )}
                      </React.Fragment>
                    );
                  })}
                  <Grid
                    className={styles.container}
                    container
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      className={styles.textButton}
                      variant="text"
                      startIcon={
                        <Add className={styles.icon} fontSize="small" />
                      }
                      onClick={() =>
                        push({
                          Relationship: "",
                          name: "",
                          DateOfBirth: "",
                          gender: "",
                          country: "",
                          emirate: "",
                          city: "",
                          region: "",
                        })
                      }
                    >
                      {t("Add Dependent")}
                    </Button>
                  </Grid>
                  <Divider style={{ margin: "20px 0" }} />
                </>
              )}
            </FieldArray>
            <ActionButtons activeStep={activeStep} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmployeeDependentDetails;
