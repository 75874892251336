import * as Yup from "yup";

// Updated validation schema
export const getemployeeVisaValidation = (t) => {
  return Yup.object().shape({
    employeeVisaInfo: Yup.object().shape({
      freshGraduate: Yup.boolean().required(
        t("Please specify if fresh graduate")
      ),
      visaType: Yup.number()
        .nullable()
        .transform((value) => (value === "" ? undefined : value)) // Converts empty string to undefined
        .required(t("Visa Type is required")) // Make it required
        .test(
          "is-zero",
          t("Please select an item in the list"),
          (value) => value !== 0 // Ensure it is not zero
        ),
      Emirate: Yup.number()
        .nullable()
        .transform((value) => (value === "" ? undefined : value)) // Converts empty string to undefined
        .when("employeeVisaInfo.nationality", {
          is: t("United Arab Emirates"), // Check if nationality is "United Arab Emirates"
          then: Yup.number()
            .required(t("Emirate is required")) // Require if nationality matches
            .test(
              "is-zero",
              t("Emirate cannot be zero"),
              (value) => value !== 0
            ),
          otherwise: Yup.number().nullable(), // Allow null otherwise
        }),
      // visaNumber: Yup.string().required(t("Visa Number is required")),
      visaNumber: Yup.string()
        .required(t("Visa Number is required"))
        .matches(
          /^[A-Za-z0-9/]+$/, // Only allow English letters, numbers, and the '/' character
          t("Please enter valid value")
        )
        .max(20, t("Character count exceeded")),
      
      issueDate: Yup.date()
        .required(t("Issue Date is required"))
        .max(new Date(), t("Issue Date cannot be later than today")),
    
      expiryDate: Yup.date()
        .typeError(t("Expiry Date is required"))
        .required(t("Expiry Date is required"))
        .test(
          "is-future",
          t("Expiry Date must be in the future"),
          function (value) {
            return value && value > new Date(); // Expiry must be after today
          }
        )
        .min(
          Yup.ref("issueDate"),
          t("Expiry Date cannot be before Issue Date")
        ),
        WorkPermitNumber: Yup.string().when("visaType", {
          is: 3, // Family Visa (dropdownValue: 3)
          then: Yup.string().required(t("Work Permit Number is required")),
          otherwise: Yup.string().notRequired(), // Optional if not Family Visa
        }),
      currentJob: Yup.string().when("freshGraduate", {
        is: false,
        then: Yup.string().required(t("Current Job is required")),
        otherwise: Yup.string().nullable(),
      }).matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")), // Set max length,

      company: Yup.string().when("freshGraduate", {
        is: false,
        then: Yup.string().required(t("Company is required")),
        otherwise: Yup.string().nullable(),
      }).matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")), // Set max length,

      // sponsorNumber: Yup.string().when("freshGraduate", {
      //   is: false,
      //   then: Yup.string().required(t("Sponsor Number is required")),
      //   otherwise: Yup.string().nullable(),
      // }).matches(
      //   /^[A-Za-z\u0600-\u06FF\s\-.']+$/, // Allow English, Arabic, spaces, and the characters '-', '.', and '''
      //   t("Please enter valid value")
      // )
      // .max(100, t("Character count exceeded")),
      sponsorNumber: Yup.string().when("freshGraduate", {
        is: false,
        then: Yup.string().required(t("Sponsor Number is required")),
        otherwise: Yup.string().nullable(),
      }).max(100, t("Character count exceeded")),

      issuancePlace: Yup.string().when("freshGraduate", {
        is: false,
        then: Yup.string().required(t("Issuance Place is required")),
        otherwise: Yup.string().nullable(),
      }).matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")), // Set max length,
      
      CompanyVisaAndFamilyVisa: Yup.string().when("freshGraduate", {
        is: false,
        then: Yup.string().required(t("Please select an item in the list")),
        otherwise: Yup.string().nullable(),
      }),
    }),
  });
};
