import {
  Button,
  Divider,
  Grid,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import { Add, CheckBox, Remove } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import SearchableDropdownOB from "../../../components/SearchableDropdownOB/SearchableDropdownOB";
import InputField from "../../../components/InputField/InputField";
// import SuffexInputField from "../../../components/SuffexInputField/SuffexInputField";
import { getLookupOptions } from "../../../lookupData.options";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./PreviousEmployee.styles";
import { Form, FieldArray, Field, Formik, ErrorMessage } from "formik";
import { getPreviousEmployeeValidation } from "./validation";
import classNames from "classnames";
import {
  setActiveStep,
  updateFormData,
  submitFormData,
  fetchLookupData,
  // fetchApplicantData
} from "../../../store/reducers/onboardingReducer";
import ClipLoader from "react-spinners/ClipLoader"; // Optional, if using react-spinners

const PreviousEmployee = ({nationality}) => {
  const { i18n, t } = useTranslation();
  const styles = useStyle();
  const { dir } = useSelector((state) => state.generalStates);
  const [isAddingFields, setIsAddingFields] = useState(false);

  const [selectedNationality, setSelectedNationality] = useState({nationality});

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const handleAddEmployee = () => {
    setIsAddingFields(true); // When clicked, set to true to show the fields
  };
  const {
    activeStep,
    previousEmployeeInfo,
    status,
    lookupData,
    parentId,
    uniqueRecordId,
  } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [countryOptions, setCountryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const previousEmployeeInfoOptions = useMemo(
    () => getLookupOptions(dir),
    [dir]
  );

  // Fetch lookup data on component mount
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLookupData(["Country"]));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "succeeded") {
      // Update country options
      if (lookupData.Country) {
        setCountryOptions(
          lookupData.Country.map((item) => ({
            labelEN: item.Value, // English label
            labelAR: item.ValueAR, // Arabic label
            value: item.Key,
          }))
        );
      }
    }
  }, [lookupData, status]);

  const initialPreviousEmployeeInfo = Array.isArray(previousEmployeeInfo) && previousEmployeeInfo.length > 0
  ? previousEmployeeInfo.map(employee => ({
      JobTitle: employee.JobTitle || "", // Job Title Textbox
      CompanyName: employee.CompanyName || "", // Company Name Textbox
      Country: employee.Country || "", // Country Textbox
      CompanyType: employee.CompanyType || "", // Company Type Textbox
      StartDate: employee.StartDate || "", // Start Date Date Picker
      EndDate: employee.EndDate || "", // End Date Date Picker
      ReasonforLeaving: employee.ReasonforLeaving || "", // Reason for Leaving Textbox
      ExperienceYears: employee.ExperienceYears || "0.0", // Experience Years Textbox
    }))
  : [];




  const calculateExperience = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      let years = end.getFullYear() - start.getFullYear();
      let months = end.getMonth() - start.getMonth();
      if (months < 0) {
        years--;
        months += 12;
      }
      // return years + months / 12;
      return (years + months / 12).toFixed(1); // Ensure two decimal places
    }
    // return 0;
    return "0.0"; // Return as string to match formatting
  };
  const calculateTotalExperience = (previousEmployeeInfo) => {
    if (!previousEmployeeInfo || !Array.isArray(previousEmployeeInfo)) {
      // return 0;
      return "0.0";
    }
    const total = previousEmployeeInfo.reduce((total, job) => {
      return (
        total + parseFloat(calculateExperience(job.StartDate, job.EndDate))
      );
    }, 0);
    return total.toFixed(1); // Ensure two decimal places
  };
  const [totalExperience, setTotalExperience] = useState(
    calculateTotalExperience(previousEmployeeInfo)
  );

  const onClickSubmit = async (values) => {
    setIsLoading(true);
    // console.log("onClickSubmit triggered with values: ", values); // Debugging line

    // Merge additional fields into previousEmployeeInfo
    const previousEmployeeInfo = values.previousEmployeeInfo.map(
      (employee) => ({
        ...employee,
        reasonForLeaving: values.reasonForLeaving,
        acknowledge: values.acknowledge,
        // totalExperience: calculateTotalExperience(values.previousEmployeeInfo),
      })
    );

    // console.log("Processed employee data: ", previousEmployeeInfo); // Check processed data
    // Dispatch the current form data to Redux, including the step number
    dispatch(updateFormData({ step: activeStep, data: previousEmployeeInfo }));
    try {
      const result = await dispatch(
        submitFormData({ previousEmployeeInfo, activeStep, parentId })
      );
      if (result.meta.requestStatus === "fulfilled") {
        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        dispatch(setActiveStep(activeStep + 1));
        // console.log("Form submitted successfully");
        setIsLoading(false);
      } else {
        throw new Error(
          result.payload || t("Something went wrong. We are having trouble completing your request, please try again")
        );
        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      // console.error("Submission error:", error);
      setSnackBarMessage(
        t(
          "Something went wrong. We are having trouble completing your request, please try again"
        )
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state in both success and error cases
      setSnackBarOpen(true); // Open Snackbar with the message
    }
  };
  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    //height: '100vh'            // Full viewport height
  };

  const loaderStyle = {
    border: "8px solid #f3f3f3", // Light grey
    borderTop: "8px solid #3498db", // Blue
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  };

  const spinKeyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  if (isLoading) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinKeyframes}</style> {/* Add the spin animation */}
        <div style={loaderStyle}></div> {/* Custom loader */}
      </div>
    );
  }

  const isArabic = i18n.language === "ar";

  return (
    <>
    <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: isArabic ? "left" : "right",
        }} // Positioning
        style={{ top: "100px" }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    <Formik
    initialValues={{
      previousEmployeeInfo: initialPreviousEmployeeInfo,
      acknowledge: previousEmployeeInfo?.[0]?.acknowledge || false,
    }}
      validationSchema={getPreviousEmployeeValidation(t)}
      onSubmit={(values) => onClickSubmit(values)}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <Form>
            <FieldArray name="previousEmployeeInfo">
              {({ push, remove }) => (
                <>
                  {/* {values.previousEmployeeInfo.length > 0  && isAddingFields && ( */}
                    <>
                      {values.previousEmployeeInfo.map((_, index) => (
                        <React.Fragment key={index}>
                          {values.previousEmployeeInfo.length > 0 && (
                            <Button
                              className={styles.textButtonRemove}
                              variant="text"
                              color="secondary"
                              startIcon={<Remove fontSize="small" />}
                              onClick={() => remove(index)}
                            >
                              {t("Remove Employee")}
                            </Button>
                          )}
                          <Grid
                            className={styles.container}
                            container
                            direction="row"
                            justifyContent="flex-start"
                          >
                            {/* Job Title Field */}
                            <Field
                              containerClass={styles.inputField}
                              name={`previousEmployeeInfo[${index}].JobTitle`}
                              placeholder="Job Title"
                              component={InputField}
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.JobTitle &&
                                errors.previousEmployeeInfo?.[index]?.JobTitle
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.JobTitle &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.JobTitle
                                  ),
                              })}
                              value={
                                values.previousEmployeeInfo[index].JobTitle
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].JobTitle`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].JobTitle`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            {/* Company Name Field */}
                            <Field
                              containerClass={styles.inputField}
                              name={`previousEmployeeInfo[${index}].CompanyName`}
                              placeholder="Company Name"
                              component={InputField}
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.CompanyName &&
                                errors.previousEmployeeInfo?.[index]
                                  ?.CompanyName
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.CompanyName &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.CompanyName
                                  ),
                              })}
                              value={
                                values.previousEmployeeInfo[index].CompanyName
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].CompanyName`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].CompanyName`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            {/* Country Dropdown */}
                            {/* <SearchableDropdownOB
                              name={`previousEmployeeInfo[${index}].Country`}
                              containerClass={`${styles.inputField}`}
                              options={countryOptions}
                              placeholder="Country"
                              onChange={(_, value) => {
                                const selectedKey = value?.value;
                                // Update the field value correctly
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].Country`,
                                  selectedKey
                                );
                              }}
                              value={
                                countryOptions?.find(
                                  (option) =>
                                    option.value ===
                                    values.previousEmployeeInfo[index].Country
                                ) || null // Ensure value is null if not found
                              }
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.Country &&
                                errors.previousEmployeeInfo?.[index]?.Country
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.Country &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.Country
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].Country`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].Country`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            /> */}
                            <SearchableDropdownOB
                              name={`previousEmployeeInfo[${index}].Country`}
                              containerClass={`${styles.inputField}`}
                              // options={countryOptions}
                              options={countryOptions.map((option) => ({
                                label: dir === "rtl" ? option.labelAR : option.labelEN, // Choose label based on direction
                                value: option.value,
                              }))}
                              placeholder="Country"
                              onChange={(_, value) => {
                                const selectedKey = value?.value;
                                // Update the field value correctly
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].Country`,
                                  selectedKey
                                );
                              }}
                              value={
                                countryOptions?.find(
                                  (option) =>
                                    option.value === values.previousEmployeeInfo[index].Country
                                )
                                  ? {
                                      label: dir === "rtl" 
                                        ? countryOptions.find(
                                            (option) => option.value === values.previousEmployeeInfo[index].Country
                                          ).labelAR 
                                        : countryOptions.find(
                                            (option) => option.value === values.previousEmployeeInfo[index].Country
                                          ).labelEN,
                                      value: values.previousEmployeeInfo[index].Country,
                                    }
                                  : { label: "", value: "" } // Default if no match is found
                              }
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.Country &&
                                errors.previousEmployeeInfo?.[index]?.Country
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.Country &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.Country
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].Country`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].Country`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            {/* Company Type Dropdown */}
                            <Field
                              name={`previousEmployeeInfo[${index}].CompanyType`}
                              label="Type of Company"
                              containerClassName={styles.dropdown}
                              component={Dropdown}
                              options={
                                previousEmployeeInfoOptions.TypeofCompany
                              }
                              onChange={(event) => {
                                const value = event.target.value;
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].CompanyType`,
                                  value
                                );
                              }}
                              value={
                                values.previousEmployeeInfo[index].CompanyType
                              }
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.CompanyType &&
                                errors.previousEmployeeInfo?.[index]
                                  ?.CompanyType
                              }
                              className={classNames(styles.dropdownInput, {
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.CompanyType &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.CompanyType
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].CompanyType`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].CompanyType`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            {/* Start Date Field */}
                            <Field
                              name={`previousEmployeeInfo[${index}].StartDate`}
                              placeholder="Start Date"
                              component={CustomDatePicker}
                              onChange={(date) => {
                                const isoDate = date instanceof Date && !isNaN(date) ? date.toISOString() : "";
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].StartDate`,
                                  isoDate
                                );
                                // Calculate experience
                                const experience = calculateExperience(
                                  isoDate,
                                  values.previousEmployeeInfo[index].EndDate
                                );
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].ExperienceYears`,
                                  experience
                                );
                                const updatedExperience =
                                  calculateTotalExperience(
                                    values.previousEmployeeInfo
                                  );
                                setFieldValue(
                                  "totalExperience",
                                  updatedExperience
                                );
                                setTotalExperience(updatedExperience);
                              }}
                              value={
                                values.previousEmployeeInfo[index].StartDate
                              }
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.StartDate &&
                                errors.previousEmployeeInfo?.[index]?.StartDate
                              }
                              className={classNames(styles.inputField, {
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.StartDate &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.StartDate
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].StartDate`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].StartDate`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            {/* End Date Field */}
                            <Field
                              name={`previousEmployeeInfo[${index}].EndDate`}
                              placeholder="End Date"
                              component={CustomDatePicker}
                              onChange={(date) => {
                                const isoDate = date instanceof Date && !isNaN(date) ? date.toISOString() : "";
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].EndDate`,
                                  isoDate
                                );
                                // Calculate experience
                                const experience = calculateExperience(
                                  values.previousEmployeeInfo[index].StartDate,
                                  isoDate
                                );
                                setFieldValue(
                                  `previousEmployeeInfo[${index}].ExperienceYears`,
                                  experience
                                );
                                const updatedExperience =
                                  calculateTotalExperience(
                                    values.previousEmployeeInfo
                                  );
                                setFieldValue(
                                  "totalExperience",
                                  updatedExperience
                                );
                                setTotalExperience(updatedExperience);
                              }}
                              value={values.previousEmployeeInfo[index].EndDate}
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.EndDate &&
                                errors.previousEmployeeInfo?.[index]?.EndDate
                              }
                              className={classNames(styles.inputField, {
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.EndDate &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.EndDate
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].EndDate`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].EndDate`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            {/* Reason for Leaving Field */}
                            <Field
                              containerClass={styles.inputField}
                              name={`previousEmployeeInfo[${index}].ReasonforLeaving`}
                              placeholder="Reason for Leaving"
                              component={InputField}
                              value={
                                values.previousEmployeeInfo[index]
                                  .ReasonforLeaving
                              }
                              helperText={
                                touched.previousEmployeeInfo?.[index]
                                  ?.ReasonforLeaving &&
                                errors.previousEmployeeInfo?.[index]
                                  ?.ReasonforLeaving
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.previousEmployeeInfo?.[index]
                                    ?.ReasonforLeaving &&
                                  Boolean(
                                    errors.previousEmployeeInfo?.[index]
                                      ?.ReasonforLeaving
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].ReasonforLeaving`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `previousEmployeeInfo[${index}].ReasonforLeaving`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                          </Grid>
                          {index < values.previousEmployeeInfo.length - 1 && (
                            <Divider className={styles.divider} />
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  {/* )} */}

                  <Grid
                    className={styles.container}
                    container
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      className={styles.textButton}
                      variant="text"
                      startIcon={
                        <Add className={styles.icon} fontSize="small" />
                      }
                      onClick={() => {
                        // This should trigger the addition of fields
                        push({
                          JobTitle: "",
                          CompanyName: "",
                          Country: "",
                          CompanyType: "",
                          StartDate: "",
                          EndDate: "",
                          ReasonforLeaving: "",
                          ExperienceYears: 0,
                        });
                        // Show fields only if isAddingFields is false
                        if (!isAddingFields) {
                          setIsAddingFields(true);
                        }
                      }}
                    >
                      {t("Add Employee")}
                    </Button>
                  </Grid>

                  {/* Acknowledge Field and Action Buttons */}
                  {/* {!isAddingFields && ( */}
                  <>
                    <Grid
                      container
                      spacing={3}
                      style={{ marginBottom: "20px" }}
                    >
                      <Grid item xs={12}>
                        <Field
                          name="acknowledge"
                          component={({ field, form }) => (
                            <FormControlLabel
                              control={
                                <MuiCheckbox
                                  checked={field.value || false}
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      field.name,
                                      e.target.checked
                                    )
                                  }
                                  onBlur={() =>
                                    form.setFieldTouched(field.name, true)
                                  }
                                />
                              }
                              label={t(
                                "I acknowledge that all the data mentioned above is correct"
                              )}
                              style={{ width: "100%" }}
                            />
                          )}
                          helperText={touched.acknowledge && errors.acknowledge}
                          className={classNames({
                            [styles.error]:
                              touched.acknowledge &&
                              Boolean(errors.acknowledge),
                          })}
                        />
                        <ErrorMessage
                          name="acknowledge"
                          component="div"
                          className="error"
                          style={{ color: "red", fontSize: "11px" }}
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ margin: "20px 0" }} />
                    <ActionButtons nationality={selectedNationality} activeStep={activeStep} />
                  </>
                  {/* )} */}
                </>
              )}
            </FieldArray>
          </Form>
        </>
      )}
    </Formik>
    </>
  );
};

export default PreviousEmployee;
